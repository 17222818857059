import Header from './../Header';

export default function PageCategories() {
  return (
    <>
    <Header />
    <div>
      Page categories
    </div>
    </>
  );
}