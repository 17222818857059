import ItemSousMenu from './ItemSousMenu';
import {NavLink} from 'react-router-dom'
import { useState } from 'react';

export default function ItemMenu(props){
  const [hasActiveChild, setHasActiveChild] = useState(false);
return (
  <li>
    <NavLink to={props.lien} className={({ isActive }) => (isActive || hasActiveChild? 'active' : 'inactive')} >      
      <i className={ props.icon }></i>{ props.txt }
    </NavLink>
  <ul>      
        {props.childrens.map(ssitem => {
          return <ItemSousMenu
          updateActiveChild = {setHasActiveChild}
          txt = {ssitem.title}
          key = {ssitem.title}
          id = {ssitem.title}
          lien = {ssitem.link}
          />;
        })}
        </ul>
  
  </li>

);
}