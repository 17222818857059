import {NavLink} from 'react-router-dom'
import React, { useEffect } from 'react';

export default function ItemSousMenu(props){
  var ia=false;
  useEffect(() => {
    // Met à jour le titre du document via l’API du navigateur
   // props.updateActiveChild(true);  
   console.log('eee', ia);
  });
return (
  <li><NavLink to={props.lien} onClick={()=>{props.updateActiveChild(true);  }} className={({ isActive }) => {
    ia = isActive;
    console.log({isActive});
    return (isActive ? 'active' : 'inactive')
  }} >{ props.txt }</NavLink></li>  
);
}