import Header from './../Header';

export default function PageCategorie(){
    return (
      <>
        <Header />
        <div>
          Categorie
        </div>
      </>
    )
}