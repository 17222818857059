import Header from './../Header';
import React, { useState,useEffect} from "react";
import Config from './../../config.json';
import axios from "axios";
import {useParams} from 'react-router-dom'

function PageArticle() {
  const [article, setArticle] = useState([]);
  const {params} = useParams();

  useEffect(() => {
    console.log(params);
    document.title = "Bienvenue sur Veilleinformatique.com";  
    axios.get(`${Config.api.article}`+'/'+params.slug).then((response) => {
      console.log(response);
      setArticle(response.data.data);
    });
  }, []);

return <>
<Header />
<h1>Article</h1>
{ article.contenu }
</>
}

export default PageArticle;