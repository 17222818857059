import React from 'react'
import {useNavigate} from 'react-router-dom';


function ArticleListing(props) {
console.log(props);
const navigate = useNavigate();
return <>
<h2>{ props.titre}</h2>
<p>{props.resume}</p>
<button onClick={()=>{navigate('/article/'+props.id)}} >A propos de ...</button>
</>
}

export default ArticleListing;