import React from 'react';
import Config from './../config.json';
import ItemMenu from './Widget/ItemMenu';

import './Navbar.css';

function Navbar() {

return (<nav className="main-menu">  
 			   <ul>
    {Config.menu.map(item => {

      return (
        <>
        <ItemMenu 
        txt = {item.title}
        key = {item.title}
        id = {item.title}
        lien = {item.link}
        icon = {item.icon}
        childrens = {item.childrens}
        />  
        </>
      );
    })}
         </ul>
      </nav>
);}

export default Navbar;