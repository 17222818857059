import Header from './../Header';

function PageAProposDe() {
return <>
<Header />
<h1>A propos de </h1>
<p>Veille Informatique est un site créé dédié au partage de nouvelles technologies, ressources web, trucs et astuces, notes diverses. Il s'adresse aux développeurs informatiques, intégrateurs, mais plus généralement toute personne intéressée par l'actualité informatique.

Merci pour votre visite et bonne lecture !</p>
</>
}

export default PageAProposDe;