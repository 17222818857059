import './App.css';
import PageAccueil from './Components/Pages/PageAccueil'
import PageAProposDe from './Components/Pages/PageAProposDe'
import PageArticle from './Components/Pages/PageArticle.js'
import PageCategorie from './Components/Pages/PageCategorie.js'
import PageCategories from './Components/Pages/PageCategories.js'
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom'
import Footer from './Components/Footer'

function App() {

  return (
    <>
    <Router>
      <Routes>
        <Route path="/" element={<PageAccueil/>} />
        <Route path="/article/:slug" element={<PageArticle/>} />
        <Route path="/categories" element={<PageCategories/>} />
        <Route path="/categorie/:slug" element={<PageCategorie/>} />
        <Route path="/page/a-propos-de" element={<PageAProposDe/>} />
      </Routes>
    </Router>
    <Footer />
    </>
  );
}

export default App;

