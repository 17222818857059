import Header from './../Header';
import React, { useState,useEffect} from "react";
import Config from './../../config.json';
import axios from "axios";
import ArticleListing from './../Widget/ArticleListing';

function PageAccueil(){

  const [liste, setListe] = useState([]);
console.log(liste);
  useEffect(() => {
    document.title = "Bienvenue sur Veilleinformatique.com";  


    axios.get(`${Config.api.articles}`).then((response) => {
      console.log(response);

      setListe(response.data.data);
    });
    /*axios.get(`${Config.article}/5`).then((response) => {
      console.log(response);
      //setPost(response.data);
    });*/
  }, []);
  return <>
  <Header />
  <div className='container'>
    <div className='content'>PageAccueil
    {liste.map((value, index) => {
        return <ArticleListing 
        key={index} 
        id={value.id}
        titre={value.titre}
        resume={value.resume}
        slug={value.slug}        
        >{value.titre}</ArticleListing>
      })}
    </div>
    <div className='sidePanel'></div>
  </div>
  </>
}

export default PageAccueil;